<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col>
                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                  {{ $t('fertilizerSales.pos_memo') }}
                </list-report-head>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8" sm="8"></b-col>
              <b-col lg="4" sm="4">
                <b-table-simple borderless style="font-size:12px;" class="remove-margin-padding">
                  <tbody>
                    <tr>
                      <th>{{ $t('fertilizerSales.first_copy') }}</th>
                      <th class="text-center">:</th>
                      <td>{{ $t('fertilizerSales.dealer') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('fertilizerSales.second_copy') }}</th>
                      <th class="text-center">:</th>
                      <td>{{ $t('fertilizerSales.godown') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('fertilizerSales.third_copy') }}</th>
                      <th class="text-center">:</th>
                      <td>{{ $t('fertilizerSales.office') }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
              <b-col lg="7" sm="7">
                <b-table-simple borderless style="font-size:12px;" class="remove-margin-padding">
                  <tbody>
                    <tr>
                      <td>{{ $t('globalTrans.date') }}</td>
                      <td>:</td>
                      <td>{{ formData.sale_date | dateFormat }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('fertilizerSales.cash_memo') }}</td>
                      <td>:</td>
                      <td>{{ $n(formData.memo_no) }}</td>
                    </tr>
                    <b-tr>
                      <td>{{ $t('fertilizerSales.sales_center') }}</td>
                      <td>:</td>
                      <td>{{ currentLocale === 'en' ? formData.sale_center : formData.sale_center_bn }}</td>
                    </b-tr>
                    <b-tr>
                      <td>{{ $t('fertilizerSales.godown') }}</td>
                      <td>:</td>
                      <td>{{ currentLocale === 'en' ? formData.godown : formData.godown_bn }}</td>
                    </b-tr>
                    <tr>
                      <td>{{ $t('fertilizerSales.dealer_code') }}</td>
                      <td>:</td>
                      <td>{{ formData.dealer_code }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('fertilizerSales.dealer_type') }}</td>
                      <td>:</td>
                      <td>{{ formData.dealer_type_id === 1 ? $t('fertilizerSales.seeds') : $t('fertilizerSales.fertilizer') }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('fertilizerSales.trade_name') }}</td>
                      <td>:</td>
                      <td>{{ currentLocale === 'en' ? formData.trade : formData.trade_bn }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('fertilizerReport.ownerName') }}</td>
                      <td>:</td>
                      <td>{{ currentLocale === 'en' ? formData.dealer : formData.dealer_bn }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('fertilizerSales.dealer_licence') }}</td>
                      <td>:</td>
                      <td>{{ formData.licence_number }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('fertilizerSales.issue_date') }}</td>
                      <td>:</td>
                      <td>{{ formData.licence_issue_date | dateFormat }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('globalTrans.district') }}</td>
                      <td>:</td>
                      <td>{{  currentLocale === 'en' ? formData.pre_district_id.text_en : formData.pre_district_id.text_bn }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('globalTrans.upazila') }}</td>
                      <td>:</td>
                      <td>{{  currentLocale === 'en' ? formData.pre_upazilla_id.text_en : formData.pre_upazilla_id.text_bn }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
              <b-col lg="5" sm="5">
                <b-table-simple bordered  style="font-size:12px;" class="remove-margin-padding">
                  <tbody>
                    <b-tr>
                      <th colspan="4" class="text-center">{{ $t('fertilizerSales.dealer_alloc_info') }}</th>
                    </b-tr>
                    <tr>
                      <td class="text-center">{{ $t('movement.fertilizerName') }}</td>
                      <td class="text-right">{{ $t('fertilizerSales.allocated_amount') }}</td>
                      <td class="text-right">{{ $t('fertilizerSales.purchase_amount') }}</td>
                      <td class="text-right">{{ $t('fertilizerSales.rest_amount') }}</td>
                    </tr>
                      <tr v-for="(item, index) in allocation" :key="index">
                      <td class="text-center">{{ getFertilizer(item.fertilizer_id) }}</td>
                      <td class="text-right">{{ $n(item.allocated_amount) }}</td>
                      <td class="text-right">{{ $n(item.puchased_amount) }}</td>
                      <td class="text-right">{{ $n(item.rest_amount) }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
              <!-- {{ formData }} -->
            </b-row>
            <b-row>
              <div class="col text-right">
                <span class="text-right text-dark font-weight-bold"> {{ $t('fertilizerReport.accountMayTons') }}</span>
              </div>
              <b-col lg="12" sm="12">
                <b-table-simple bordered style="font-size:12px;" class="remove-margin-padding">
                  <b-thead head-variant="secondary">
                      <b-tr>
                          <b-th rowspan="2" class="text-center">{{ $t('movement.fertilizerName') }}</b-th>
                          <b-th rowspan="2" class="text-center">{{$t('fertilizerSales.source_country')}}</b-th>
                          <b-th colspan="2" class="text-center">{{ $t('fertilizerSales.quantity') }}</b-th>
                          <b-th rowspan="2" class="text-center">{{ $t('fertilizerSales.rate_per_ton') }}</b-th>
                          <b-th rowspan="2" class="text-center">{{ $t('fertilizerSales.purchase_amount') }}</b-th>
                          <b-th colspan="4" class="text-center">{{ $t('fertilizerSales.money_recieve') }}</b-th>
                          <b-th rowspan="2" class="text-center">{{$t('fertilizerSales.comment')}}</b-th>
                      </b-tr>
                      <b-tr>
                          <b-th class="text-center">{{ $t('fertilizerSales.bag_no') }}</b-th>
                          <b-th class="text-center">{{ $t('fertilizerSales.amount_ton') }}</b-th>
                          <b-th class="text-center">{{ $t('fertilizerSales.pay_orders') }}</b-th>
                          <b-th class="text-center">{{ $t('globalTrans.date') }}</b-th>
                          <b-th class="text-center">{{ $t('fertilizerSales.bank') }} {{' & '}} {{ $t('fertilizerSales.branch') }}</b-th>
                          <b-th class="text-center">{{ $t('fertilizerSales.total_price') }}</b-th>
                      </b-tr>
                      <b-tr>
                          <b-td class="text-center">{{ $n(1) }}</b-td>
                          <b-td class="text-center">{{ $n(2) }}</b-td>
                          <b-td class="text-center">{{ $n(3) }}</b-td>
                          <b-td class="text-center">{{ $n(4) }}</b-td>
                          <b-td class="text-center">{{ $n(5) }}</b-td>
                          <b-td class="text-center">{{ $n(6) }}</b-td>
                          <b-td class="text-center">{{ $n(7) }}</b-td>
                          <b-td class="text-center">{{ $n(8) }}</b-td>
                          <b-td class="text-center">{{ $n(9) }}</b-td>
                          <b-td class="text-center">{{ $n(10) }}</b-td>
                          <b-td class="text-center">{{ $n(11) }}</b-td>
                      </b-tr>
                      <b-tr>
                          <b-td class="text-center">{{ $i18n.locale === 'bn' ? formData.fertilizer_bn : formData.fertilizer }}</b-td>
                          <b-td class="text-center">{{ formData.source_country }}</b-td>
                          <b-td class="text-center">{{ $n(formData.quantity * 20) }}</b-td>
                          <b-td class="text-center">{{ $n(formData.quantity) }}</b-td>
                          <b-td class="text-center">{{ $n(formData.unit_price) }}</b-td>
                          <b-td class="text-center">{{ $n(formData.quantity * formData.unit_price) }}</b-td>
                          <b-td class="text-center"><div v-for="(pay, index) in formData.pay_orders" :key="index">{{ $n(pay.pay_order_no, { useGrouping: false }) }}{{ ',' }}<br></div></b-td>
                          <b-td class="text-center"><div v-for="(pay, index) in formData.pay_orders" :key="index">{{ pay.pay_order_date | dateFormat }}{{ ',' }}<br></div></b-td>
                          <b-td class="text-center"><div v-for="(pay, index) in formData.pay_orders" :key="index">{{ getDataById(pay.bank_id, 'bank') }} {{ '-' }}{{ getDataById(pay.branch_id, 'branch') }}{{ ',' }}<br></div></b-td>
                          <b-td class="text-center"><div v-for="(pay, index) in formData.pay_orders" :key="index">{{ $n(pay.amount, { useGrouping: false }) }}{{ ',' }}<br></div></b-td>
                          <b-td class="text-center">{{ $i18n.locale === 'bn' ? formData.comments_bn : formData.comments }}</b-td>
                      </b-tr>
                      <b-tr>
                          <b-th class="text-center" colspan="4"></b-th>
                          <b-th class="text-right" >{{ $t('sitePreference.total') }}</b-th>
                          <b-th class="text-center">{{ $n(formData.quantity * formData.unit_price) }}</b-th>
                          <b-th class="text-center" colspan="5"></b-th>
                      </b-tr>
                  </b-thead>
                </b-table-simple>
              </b-col>
            </b-row>
            <br><br><br><br>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple borderless style="font-size:12px;" class="remove-margin-padding">
                  <b-thead head-variant="secondary">
                      <b-tr>
                          <b-th colspan="3" class="text-center">{{ $t('fertilizerSales.godown_keeper') }}</b-th>
                          <b-th colspan="3" class="text-center">{{ $t('fertilizerSales.sub_ast_engg') }}</b-th>
                          <b-th colspan="3" class="text-center">{{ $t('fertilizerSales.ad') }}</b-th>
                          <b-th colspan="3" class="text-center">{{ $t('fertilizerSales.dealer_signature') }}</b-th>
                      </b-tr>
                  </b-thead>
                </b-table-simple>
             </b-col>
            </b-row>
            <!-- <br><br><br><br>
            <b-row>
              <b-col lg="12" sm="12">
                <p>Signiture</p>
             </b-col>
            </b-row> -->
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
.remove-margin-padding th, td {
  margin-top: 1px  !important;
  margin-bottom: 1px  !important;
  padding-top: 1px  !important;
  padding-bottom: 1px  !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { getAllocation } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  async created () {
    if (this.id) {
      const tmp = this.getFormData()
      const temps = Object.assign(this.formData, tmp)
      this.formData = temps

      this.getAllocationDetails()
      this.formData.bag_no = this.formData.quantity * 20
      var total = 0
      tmp.pay_orders.map(item => {
        const bank = this.$store.state.commonObj.bankObj.bankList.find(doc => doc.value === item.bank_id)
        const branch = this.$store.state.commonObj.bankObj.branchList.find(doc => doc.value === item.branch_id)
        const payOrderList = {
          pay_order_no: item.pay_order_no,
          amount: item.amount,
          pay_order_date: item.pay_order_date,
          bank_id: item.bank_id,
          bank: this.$i18n.locale === 'bn' ? bank.text_bn : bank.text,
          branch: this.$i18n.locale === 'bn' ? branch.text_bn : branch.text_en,
          branch_id: item.branch_id
        }
        total += parseInt(item.amount)
        this.formData.pay_order_info.push(payOrderList)
      })
       this.total = total
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      formData: {
        id: '',
        dealer_code: '',
        dealer_id: '',
        district_id: 0,
        division_id: 0,
        fertilizer_id: 0,
        pay_order_date: '',
        sell_date: '',
        pay_order_info: [],
        point_of_sales_id: '',
        pay_order_no: '',
        amount: '',
        bank_id: 0,
        branch_id: 0,
        fiscal_year_id: 0,
        allocation_type_id: 0,
        month: 0,
        memo_no: '',
        source_country: '',
        unit_price: '',
        total_price: '',
        quantity: '',
        comments: '',
        comments_bn: ''
      },
      totalAvailableAmount: 0,
      currentAvailableAmount: 0,
      dealerInfo: [],
      allocation: [],
      errorInfo: {
        dealer: '',
        allocation: '',
        godown: '',
        quantity: '',
        total: '',
        pay: ''
      },
      godown_stock: 0,
      ministry: [],
      lcList: [],
      lcInfo: {},
      portDetailsInfo: []
    }
  },
  computed: {
    districtList: function () {
        return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    allocationTypeList: function () {
        const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
      },
    monthList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getFormattedDate (date) {
      const dateArr = date.split('-')
      return this.$n(parseInt(dateArr[0]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[1]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[2]), { useGrouping: false })
    },
    async getAllocationDetails () {
        await RestApi.postData(seedFertilizerServiceBaseUrl, getAllocation, this.formData).then(response => {
          if (response.success) {
            this.alloc = true
            const allo = response.data.map(item => {
              const fertiListObj = this.fertilizerNameList.find(ferItem => ferItem.value === item.fertilizer_id)
              const fertilizerData = {
                fertilizer_name: typeof fertiListObj !== 'undefined' ? fertiListObj.text_en : '',
                fertilizer_name_bn: typeof fertiListObj !== 'undefined' ? fertiListObj.text_bn : ''
              }
              return Object.assign({}, item, fertilizerData)
            })
            this.allocation = allo
            this.errorInfo.allocation = ''
          } else {
            this.errorInfo.allocation = response.message
            this.alloc = false
            this.allocation = ''
          }
        })
    },
    getFertilizer (Id) {
        const data = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(doc => doc.value === Id)
        if (!data) { return null }
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
    },
    getTotalAmounts (data) {
        const n = this.fertilizerNameList.length
        const a = new Array(n)
         for (let i = 0; i < n; ++i) {
            a[i] = 0
         }
         const sum1 = a
        data.forEach((district, districtIndex) => {
            district.forEach((item, fertilizerIndex) => {
                if (item.amount) {
                    sum1[fertilizerIndex] += parseInt(item.amount)
                }
                this.formData.total[fertilizerIndex] = sum1[fertilizerIndex]
            })
        })
    },
    pdfExport () {
    const reportTitle = this.$t('fertilizerSales.pos_memo')
    const bankList = this.$store.state.commonObj.bankObj.bankList
    const branchList = this.$store.state.commonObj.bankObj.branchList
    ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this, this.allocation, this.districtList, bankList, branchList)
    },
    getDataById (Id, type) {
      var data = ''
      if (type === 'org') {
        data = this.$store.state.commonObj.orgList.find(item => item.value === Id)
      }
      if (type === 'upz') {
        data = this.$store.state.commonObj.upazilaList.find(item => item.value === Id)
      }
      if (type === 'godown') {
        data = this.godownInfoList.find(item => item.value === Id)
      }
      if (type === 'fer') {
        data = this.fertilizerNameList.find(item => item.value === Id)
      }
      if (type === 'bank') {
        data = this.$store.state.commonObj.bankObj.bankList.find(item => item.value === Id)
      }
      if (type === 'branch') {
        data = this.$store.state.commonObj.bankObj.branchList.find(item => item.value === Id)
      }

      if (data) {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>
.table-bordered td, .table-bordered th {
  border: 1px solid #000 !important;
}
</style>
