<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col md="12" sm="12" class="form-for-textarea">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                    <b-row>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Dealer Code"  vid="dealer_code" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="dealer_code"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.dealer_code')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="dealer_code"
                                  v-model="formData.dealer_code"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <div class="text-danger">
                            {{ errorInfo.dealer }}
                          </div>
                        </b-col>
                        <b-col lg="12" sm="12" v-if="dealer">
                          <b-table-simple bordered >
                            <tbody>
                              <tr>
                                <th colspan="4" class="text-center">{{ $t('fertilizerSales.dealer_info') }}</th>
                              </tr>
                              <tr>
                                <td>{{ $t('fertilizerSales.dealerName') }}</td>
                                <td>{{ $i18n.locale === 'bn' ? dealerInfo.name_bn : dealerInfo.name }}</td>
                                <td>{{ $t('fertilizerSales.trade_name') }}</td>
                                <td>{{ $i18n.locale === 'bn' ? dealerInfo.trade_name_bn : dealerInfo.trade_name }}</td>
                              </tr>
                              <tr>
                                <td>{{ $t('fertilizerSales.nameOfDistrict') }}</td>
                                <td>{{ getDataById(dealerInfo.dealer_details.per_district_id, 'dis')  }}</td>
                                <td>{{ $t('fertilizerSales.nameOfUpazila') }}</td>
                                <td>{{ getDataById(dealerInfo.dealer_details.per_upazilla_id, 'upz')  }}</td>
                              </tr>
                              <tr>
                                <td>{{ $t('fertilizerSales.dealer_licence') }}</td>
                                <td>{{ dealerInfo.licence_number }}</td>
                                <td>{{ $t('fertilizerConfig.licence_issue_date') }}</td>
                                <td>{{ dealerInfo.licence_issue_date | dateFormat }}</td>
                              </tr>
                            </tbody>
                          </b-table-simple>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Allocation Type"  vid="allocation_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="allocation_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.allocationType')}}  <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.allocation_type_id"
                                :options="allocationTypeList"
                                id="allocation_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="3" sm="12">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.fiscalYear')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="3" sm="12">
                          <ValidationProvider name="Month"  vid="month_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="month_id"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('fertilizerSales.month')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.month"
                                :options="monthList"
                                id="month_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="12" sm="12" v-if="alloc">
                          <div class="text-danger">
                            {{ errorInfo.allocation }}
                          </div>
                          <b-table-simple bordered style="font-size:13px;color:#555555;line-height:2px">
                            <tbody>
                              <tr>
                                <th colspan="4" class="text-center">{{ $t('fertilizerSales.dealer_allocation_info') }}</th>
                              </tr>
                              <tr>
                                <td>{{ $t('movement.fertilizerName') }}</td>
                                <td>{{ $t('fertilizerSales.allocated_amount') }}</td>
                                <td>{{ $t('fertilizerSales.purchase_amount') }}</td>
                                <td>{{ $t('fertilizerSales.rest_amount') }}</td>
                              </tr>
                               <tr v-for="(item, index) in allocation" :key="index">
                                <td>{{ getFertilizer(item.fertilizer_id) }}</td>
                                <td>{{ $n(item.allocated_amount) }}</td>
                                <td>{{ $n(item.puchased_amount) }}</td>
                                <td>{{ $n(item.rest_amount) }}</td>
                              </tr>
                            </tbody>
                          </b-table-simple>
                        </b-col>
                        <b-col lg="12" sm="12" v-else>
                          <div class="text-danger">
                            {{ errorInfo.allocation }}
                          </div>
                        </b-col>
                    </b-row>
                    <br>
                    <b-row>
                      <div class="col text-center">
                        <h5>{{ $t('fertilizerSales.pay_order_info') }}</h5>
                        &nbsp;
                      </div>
                    </b-row>
                    <br>
                    <hr>
                    <b-row>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Pay Order No"  vid="pay_order_no" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="pay_order_no"
                                slot-scope="{ valid, invalid }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.pay_order_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="memo_no"
                                  v-model="formData.pay_order_no"
                                  :state="invalid[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ invalid[0] }}
                              </div>
                              <div class="text-danger">
                              {{ errorInfo.pay_order_no }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Pay Order Amount"  vid="amount" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="amount"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.pay_order_amount')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  type="number"
                                  id="amount"
                                  v-model="formData.amount"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Pay Order Date" vid="pay_order_date" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="pay_order_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.pay_order_date')}} <span class="text-danger">*</span>
                              </template>
                              <flat-pickr class="form-control"
                                          v-model="formData.pay_order_date"
                                          :placeholder="$t('globalTrans.select_date')"
                                          :state="errors[0] ? false : (valid ? true : null)"
                              ></flat-pickr>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Bank"  vid="bank_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="bank_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.bank')}}  <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.bank_id"
                                :options="bankList"
                                id="bank_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Branch"  vid="branch_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="branch_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.branch')}}  <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.branch_id"
                                :options="branchList"
                                id="branch_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="3" sm="6">
                          <div class="text-danger">
                            {{ errorInfo.pay }}
                          </div>
                        </b-col>
                        <div class="col text-right">
                        <b-button variant="primary" @click="addPay" class="mr-2">{{ $t('fertilizerSales.add_pay_order') }}</b-button>
                        &nbsp;
                    </div>
                    </b-row>
                    <br>
                    <h5>{{ $t('fertilizerSales.pay_orders') }}</h5>
                    <b-table-simple class="mt-3" hover bordered small caption-top responsive>
                      <b-thead head-variant="secondary">
                          <b-tr>
                              <b-th  class="text-center">{{ $t('fertilizerSales.pay_order_no') }}</b-th>
                              <b-th  class="text-center">{{ $t('fertilizerSales.pay_order_amount') }}</b-th>
                              <b-th  class="text-center">{{ $t('fertilizerSales.pay_order_date') }}</b-th>
                              <b-th  class="text-center">{{ $t('fertilizerSales.bank') }}</b-th>
                              <b-th  class="text-center">{{ $t('fertilizerSales.branch') }}</b-th>
                              <b-th  class="text-center"></b-th>
                          </b-tr>
                          <b-tr  v-for="(item, index) in formData.pay_order_info" :key="index">
                              <b-th class="text-center"><b-form-input
                                  type="text"
                                  readonly
                                  v-model="item.pay_order_no"
                              ></b-form-input></b-th>
                              <b-th class="text-center"><b-form-input
                                  type="text"
                                  readonly
                                  v-model="item.amount"
                              ></b-form-input></b-th>
                              <b-th class="text-center"><b-form-input
                                  type="text"
                                  readonly
                                  v-model="item.pay_order_date"
                              ></b-form-input></b-th>
                              <b-th class="text-center"><b-form-input
                                  type="text"
                                  readonly
                                  v-model="item.bank"
                              ></b-form-input></b-th>
                              <!-- <b-th>{{ getDataById(item.bank_id, 'bank') }}</b-th> -->
                              <b-th class="text-center"><b-form-input
                                  type="text"
                                  readonly
                                  v-model="item.branch"
                              ></b-form-input></b-th>
                              <!-- <b-th>{{ getDataById(item.branch_id, 'branch') }}</b-th> -->
                              <b-th class="text-center"><b-button variant="danger" class="mr-2" @click="remove(index)"><i class="ri-close-line m-0"></i></b-button></b-th>
                          </b-tr>
                        <b-tr>
                            <b-th class="text-center">{{ $t('fertilizerSales.total_pay_order_amount') }}</b-th>
                            <b-th class="text-center">
                              <ValidationProvider name="Pay Order"  vid="total" rules="required|numeric|min_value:1000">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="branch_id"
                                      slot-scope="{ valid, errors }"
                                  ><b-form-input
                                  type="number"
                                  readonly
                                  v-model="total"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                                </b-form-group>
                                </ValidationProvider>
                              </b-th>
                              <b-th colspan="4"></b-th>
                        </b-tr>
                      </b-thead>
                    </b-table-simple>
                    <b-row>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Sale Date" vid="sell_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="sell_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.sale_date')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                class="fromDate"
                                v-model="formData.sell_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Godown"  vid="from_godown_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="from_godown_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.godown')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.from_godown_id"
                                :options="godownInfoList"
                                id="from_godown_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <div v-if="godown_stock> 0" style="color: black">{{ $t('fertilizerSales.current_stock') + ' : '}} {{ $n(godown_stock) }}  {{ '     '}}<br> {{ $t('fertilizerSales.available_stock') + ' : '}} {{ $n(saleable_amount) }}</div>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Cash No"  vid="memo_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.cash_memo')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="memo_no"
                                  v-model="formData.memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <br>
                    <b-table-simple class="mt-3" hover borderless>
                      <b-thead head-variant="secondary">
                          <b-tr>
                              <b-th  class="text-center">{{ $t('movement.fertilizerName') }}<span class="text-danger">*</span></b-th>
                              <b-th  class="text-center">{{ $t('fertilizerSales.rate_per_ton') }}</b-th>
                              <b-th  class="text-center">{{ $t('fertilizerSales.amount_ton') }}<span class="text-danger">*</span></b-th>
                              <b-th  class="text-center">{{ $t('fertilizerSales.bag_no') }}</b-th>
                              <b-th  class="text-center">{{ $t('fertilizerSales.total_price') }}</b-th>
                          </b-tr>
                      </b-thead>
                    </b-table-simple>
                    <b-row>
                      <b-col lg="3" sm="6">
                          <ValidationProvider name="Fertilizer"  vid="fertilizer_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="bank_id"
                                slot-scope="{ valid, errors }"
                            >
                              <b-form-select
                                plain
                                v-model="formData.fertilizer_id"
                                :options="fertilizerNameList"
                                id="fertilizer_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="6">
                          <ValidationProvider name="Rate Per Ton"  vid="unit_price" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="2"
                                label-for="unit_price"
                                slot-scope="{ valid, errors }"
                            >
                              <b-form-input
                                  type="number"
                                  id="unit_price"
                                  readonly
                                  v-model="formData.unit_price"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="3" sm="6">
                          <ValidationProvider name="Quantity"  vid="quantity" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="quantity"
                                slot-scope="{ valid, errors }"
                            >
                              <b-form-input
                                  type="number"
                                  id="quantity"
                                  v-model="formData.quantity"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @keyup="getFertilizerPrice"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="6">
                          <ValidationProvider name="Bag No"  vid="bag_no" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="2"
                                label-for="bag_no"
                                slot-scope="{ valid, errors }"
                            >
                              <b-form-input
                                  type="number"
                                  id="bag_no"
                                  readonly
                                  v-model="formData.bag_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="6">
                          <ValidationProvider name="Total Price"  vid="total_price" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="2"
                                label-for="total_price"
                                slot-scope="{ valid, errors }"
                            >
                              <b-form-input
                                  type="number"
                                  id="total_price"
                                  readonly
                                  v-model="formData.total_price"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" sm="6">
                        <div class="text-danger">
                            {{ '     ' }}{{ errorInfo.godown }}
                        </div>
                      </b-col>
                      <b-col lg="2" sm="6">
                      </b-col>
                      <b-col lg="3" sm="6">
                        <div class="text-danger">
                            {{ '     ' }}{{ errorInfo.quantity }}
                        </div>
                      </b-col>
                      <b-col lg="2" sm="6">
                      </b-col>
                      <b-col lg="2" sm="6">
                        <div class="text-danger">
                            {{ '     ' }}{{ errorInfo.total }}
                        </div>
                      </b-col>
                    </b-row>
                    <br><br>
                    <div class="row">
                        <div class="col-sm-6">
                        <ValidationProvider name="Comments"  vid="comments" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="comments"
                                slot-scope="{ valid, invalid }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.comment')}} <span class="text-danger"></span>
                              </template>
                              <b-form-input
                                  id="comments"
                                  v-model="formData.comments"
                                  :state="invalid[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ invalid[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <div class="col-sm-6">
                        <ValidationProvider name="Source/Country"  vid="source_country" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="source_country"
                                slot-scope="{ valid, invalid }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.source_country')}} <span class="text-danger"></span>
                              </template>
                              <b-form-input
                                  id="source_country"
                                  v-model="formData.source_country"
                                  :state="invalid[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ invalid[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <br><br> <br><br>
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { posStore, posUpdate, getDealerInfo, getAllocation, getFertilizerInfo } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.formData = Object.assign({}, this.formData, { fiscal_year_id: this.currentFiscalYearId, month: this.currentMonthNo })
    if (this.id) {
      const tmp = this.getFormData()
      const temps = Object.assign(this.formData, tmp)
      this.formData = temps
      this.formData.bag_no = this.formData.quantity * 20
      var total = 0
      tmp.pay_orders.map(item => {
        const bank = this.$store.state.commonObj.bankObj.bankList.find(doc => doc.value === item.bank_id)
        const branch = this.$store.state.commonObj.bankObj.branchList.find(doc => doc.value === item.branch_id)
        const payOrderList = {
          pay_order_no: item.pay_order_no,
          amount: item.amount,
          pay_order_date: item.pay_order_date,
          bank_id: item.bank_id,
          bank: this.$i18n.locale === 'bn' ? bank.text_bn : bank.text,
          branch: this.$i18n.locale === 'bn' ? branch.text_bn : branch.text_en,
          branch_id: item.branch_id
        }
        total += parseInt(item.amount)
        this.formData.pay_order_info.push(payOrderList)
      })
       this.total = total
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        dealer_code: '',
        dealer_id: '',
        district_id: 0,
        division_id: 0,
        fertilizer_id: 0,
        pay_order_date: '',
        sell_date: '',
        pay_order_info: [],
        point_of_sales_id: '',
        pay_order_no: '',
        amount: '',
        bank_id: 0,
        branch_id: 0,
        fiscal_year_id: 0,
        allocation_type_id: 0,
        month: 0,
        memo_no: '',
        source_country: '',
        unit_price: '',
        total_price: '',
        quantity: '',
        comments: '',
        comments_bn: ''
      },
      info: {
          fertilizer: []
      },
      index: '',
      dealer: false,
      alloc: false,
      total: 0,
      ministry: [],
      districtList: [],
      branchList: [],
      dealerInfo: [],
      allocation: [],
      errorInfo: {
        dealer: '',
        allocation: '',
        godown: '',
        quantity: '',
        total: '',
        pay: '',
        pay_order_no: ''
      },
      godown_stock: 0,
      saleable_amount: 0,
      selectedGodown: []
    }
  },
  computed: {
    divisionList: function () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    bankList: function () {
        const data = this.$store.state.commonObj.bankObj.bankList.filter(item => item.component_id === 8)
        const tempData = data.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    allocationTypeList: function () {
        const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
      },
    monthList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    currentFiscalYearId: function () {
      return this.$store.state.SeedsFertilizer.currentFiscalYearId
    },
    currentMonthNo () {
      return (new Date()).getMonth() + 1
    }
  },
  watch: {
    'formData.dealer_code': function (newVal, oldVal) {
      this.ministry = this.getDealerDetails(newVal)
    },
    'formData.month': function (newVal, oldVal) {
      this.allocation = this.getAllocationDetails()
    },
    'formData.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
    },
    'formData.bank_id': function (newVal, oldVal) {
        this.branchList = this.getBranchList(newVal)
    },
    'formData.fertilizer_id': function (newVal, oldVal) {
        this.ministry = this.getFertilizerData()
    }
  },
  methods: {
    addPay () {
      if (this.formData.pay_order_no && this.formData.amount && this.formData.pay_order_date && this.formData.bank_id && this.formData.branch_id) {
        const bank = this.$store.state.commonObj.bankObj.bankList.find(doc => doc.value === this.formData.bank_id)
        const branch = this.$store.state.commonObj.bankObj.branchList.find(doc => doc.value === this.formData.branch_id)
        const payOrderList = {
          pay_order_no: this.formData.pay_order_no,
          amount: this.formData.amount,
          pay_order_date: this.formData.pay_order_date,
          bank_id: this.formData.bank_id,
          bank: this.$i18n.locale === 'bn' ? bank.text_bn : bank.text,
          branch: this.$i18n.locale === 'bn' ? branch.text_bn : branch.text_en,
          branch_id: this.formData.branch_id
        }
        const duplicate = this.formData.pay_order_info.find(doc => doc.pay_order_no === payOrderList.pay_order_no)
        if (!duplicate) {
          this.formData.pay_order_info.push(payOrderList)
          this.errorInfo.pay_order_no = ''
        } else {
          this.errorInfo.pay_order_no = 'Pay Order No Already Entered'
        }
        var total = 0
        this.formData.pay_order_info.map(item => {
          total += parseInt(item.amount)
        })
        this.total = total
        this.errorInfo.pay = ''

        this.formData.pay_order_no = ''
        this.formData.amount = ''
        this.formData.pay_order_date = ''
        this.formData.bank_id = ''
        this.formData.branch_id = ''
      } else {
        this.errorInfo.pay = 'All field is required.'
      }
    },
    remove (index) {
        this.formData.pay_order_info.splice(index, 1)
        var total = 0
        this.formData.pay_order_info.map(item => {
          total += parseInt(item.amount)
        })
        this.total = total
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      this.selectedGodown.forEach((item, index) => {
          this.formData.warehouse_details.push(item.value)
      })
      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${posUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, posStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        if (result.message) {
          this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        } else {
          this.$refs.form.setErrors(result.errors)
        }
      }
    },
    getDealerDetails (Id) {
        RestApi.getData(seedFertilizerServiceBaseUrl, getDealerInfo + Id).then(response => {
          if (response.success) {
            this.dealer = true
            this.formData.dealer_id = response.data.id
            this.dealerInfo = response.data
            this.errorInfo.dealer = ''
          } else {
            this.errorInfo.dealer = response.message
            this.dealer = true
            this.formData.dealer_id = ''
            this.dealerInfo = []
          }
        })
    },
     async getAllocationDetails () {
       if (this.id) {
         const data = {
           pos_id: this.formData.id
         }
         const temps = Object.assign(this.formData, data)
          this.formData = temps
       }
        await RestApi.postData(seedFertilizerServiceBaseUrl, getAllocation, this.formData).then(response => {
          if (response.success) {
            this.alloc = true
            this.allocation = response.data
            this.errorInfo.allocation = ''
          } else {
            this.errorInfo.allocation = response.message
            this.alloc = false
            this.allocation = ''
          }
        })
    },
    async getFertilizerData () {
        await RestApi.postData(seedFertilizerServiceBaseUrl, getFertilizerInfo, this.formData).then(response => {
          if (response.success) {
            const available = this.allocation.find(item => item.fertilizer_id === this.formData.fertilizer_id)
            if (available) {
              this.formData.unit_price = response.data.fertlizer_price
              this.godown_stock = response.data.godown_stock
              this.saleable_amount = response.data.saleable_amount
              this.errorInfo.godown = ''
            } else {
              this.errorInfo.godown = 'Dealer allocation Not found with this criteria.'
              this.formData.unit_price = ''
                this.godown_stock = ''
                this.saleable_amount = ''
                this.errorInfo.godown = response.message
            }
          } else {
            this.formData.unit_price = ''
            this.godown_stock = ''
            this.saleable_amount = ''
            this.errorInfo.godown = response.message
          }
        })
    },
    getFertilizer (Id) {
        const data = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(doc => doc.value === Id)
        if (!data) { return null }
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
    },
    getDataById (Id, type) {
      var data = ''
      if (type === 'dis') {
        data = this.$store.state.commonObj.districtList.find(item => item.value === Id)
      }
      if (type === 'upz') {
        data = this.$store.state.commonObj.upazilaList.find(item => item.value === Id)
      }
      if (type === 'bank') {
        data = this.$store.state.commonObj.bankObj.bankList.find(item => item.value === Id)
      }
      if (type === 'branch') {
        data = this.$store.state.commonObj.bankObj.branchList.find(item => item.value === Id)
      }

      if (data) {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return null
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
     getBranchList (bankId = null) {
      const branchList = this.$store.state.commonObj.bankObj.branchList.filter(item => item.status === 0)
      if (bankId) {
        const list = branchList.filter(branch => branch.bank_id === bankId)
        const tempData = list.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
      }
      const tempData1 = branchList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData1
    },
    getFertilizerPrice (event) {
      const available = this.allocation.find(item => item.fertilizer_id === this.formData.fertilizer_id).rest_amount
      if (available) {
        const currentId = '#' + event.target.id
        const idValAmount = document.querySelector(currentId).value
        const totalAmount = idValAmount * this.formData.unit_price
        if (((idValAmount > available) || (totalAmount > this.total)) || (idValAmount > this.saleable_amount)) {
          if (idValAmount > available) {
            this.errorInfo.quantity = 'Quantity can not be greater then Allocation.'
          }
          if (totalAmount > this.total) {
            this.errorInfo.total = 'You have not sufficient amount.'
          }
          const remainingIdVal = Math.floor(idValAmount / 10)
          this.formData.quantity = remainingIdVal
          this.formData.bag_no = this.formData.quantity * 20
          this.formData.total_price = this.formData.quantity * this.formData.unit_price
        } else {
          this.errorInfo.quantity = ''
          this.errorInfo.total = ''
          this.formData.bag_no = this.formData.quantity * 20
          this.formData.total_price = this.formData.quantity * this.formData.unit_price
        }
      } else {
        this.errorInfo.godown = 'Dealer allocation Not found with this criteria.'
      }
    }
  }
}
</script>
