<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                   <b-col lg="6" sm="12">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.fiscalYear')}}<span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Month"  vid="month_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="month_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.month')}}  <span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.month"
                                :options="monthList"
                                id="month_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fertilizer"  vid="allocation_type_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fertilizer_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerConfig.fertilizer_name')}}  <span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.fertilizer_id"
                                :options="fertilizerNameList"
                                id="fertilizer_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Godown"  vid="district_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.godown')}}  <span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.godown_id"
                                :options="godownInfoList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Dealer Code"  vid="dealer_code" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="dealer_code"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.dealer_code')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="dealer_code"
                                  v-model="search.dealer_code"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Sale Date" vid="sell_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="sell_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.sale_date')}} <span class="text-danger">*</span>
                              </template>
                              <flat-pickr class="form-control"
                                          v-model="search.sell_date"
                                          :placeholder="$t('globalTrans.select_date')"
                                          :state="errors[0] ? false : (valid ? true : null)"
                              ></flat-pickr>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    <b-col class="col-sm-2 col-md-12">
                        <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('fertilizerSales.point_of_sale')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                        <!-- <b-button variant="danger" v-b-modal.modal-5>
                             {{  $t('globalTrans.excelImport') }}
                        </b-button> -->
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(memo_no)="data">
                                          {{ $n(data.item.memo_no, { useGrouping: false }) }}
                                        </template>
                                         <template v-slot:cell(sell_date)="data">
                                          {{ data.item.sell_date | dateFormat }}
                                        </template>
                                         <template v-slot:cell(lc_date)="data">
                                           {{ $n(parseInt(data.item.lc_date.split('-')[0])) + '-' +  $n(data.item.lc_date.split('-')[1]) + '-' + $n(data.item.lc_date.split('-')[2]) }}
                                           </template>
<!--                                           <template v-slot:cell(fiscal_year)="data">-->
<!--                                           {{ $n(data.item.fiscal_year.split('-')[0], { useGrouping: false }) + '-' +  $n(data.item.fiscal_year.split('-')[1], { useGrouping: false }) }}-->
<!--                                           </template>-->
                                          <template v-slot:cell(quantity)="data">
                                            {{ $n(data.item.quantity, { useGrouping: false }) }}
                                          </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.draft')}}</span>
                                            <span class="badge badge-success" v-else>{{$t('globalTrans.save')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-5 variant=" iq-bg-warning mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="fas fa-eye"></i></b-button>
                                            <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" :disabled="data.item.status == 2"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)" :disabled="data.item.status == 2"><i class="ri-delete-bin-line m-0"></i></b-button>
                                            <b-button variant=" iq-bg-info" v-if="data.item.status == 1" size="sm" @click="finalSave(data.item)" :disabled="data.item.status == 2"><i class="far fa-check-square m-0"></i></b-button> -->
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-5 size="sm" @click="edit(data.item)">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)" v-if="data.item.status === 1">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Delete" size="sm" @click="remove(data.item)"  v-if="data.item.status === 1">
                                                <i class="ri-delete-bin-line"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Final Record" size="sm" @click="finalSave(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-check"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="xl" :title="$t('fertilizerSales.point_of_sale') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <template #modal-title class="flex-grow-1">
            {{ $t('fertilizerSales.point_of_sale') + ' ' + $t('globalTrans.details') }}
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { posList, posFinalSave, posDestroy } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form,
        Details
    },
    data () {
        return {
            search: {
                id: 0,
                fiscal_year_id: 0,
                month: 0,
                fertilizer_id: 0,
                godown_id: 0,
                dealer_code: '',
                sale_date: ''
            }
        }
    },
    computed: {
        divisionList: function () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        bankList: function () {
            return this.$store.state.commonObj.bankObj.bankList.filter(item => item.component_id === 8)
        },
        fiscalYearList: function () {
          const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
          const tempData = fiscalYears.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.text_bn, value: item.value }
            } else {
              return { text: item.text_en, value: item.value }
            }
          })
          return tempData
        },
        allocationTypeList: function () {
            const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
            const tempData = type.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { text: item.text_bn, value: item.value }
            } else {
                return { text: item.text_en, value: item.value }
            }
            })
            return tempData
        },
        monthList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
        },
        fertilizerNameList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
        },
        godownInfoList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
        },
        dealerInfoList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.dealerBasicList.filter(item => item.status === 1)
        },
        currentLocale: function () {
          return this.$i18n.locale
        },
        formTitle () {
            return this.editItemId === 0 ? this.$t('fertilizerSales.point_of_sale') + ' ' + this.$t('globalTrans.entry') : this.$t('fertilizerSales.point_of_sale') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('fertilizerSales.cash_memo'), class: 'text-center' },
                { label: this.$t('fertilizerSales.sale_date'), class: 'text-center' },
                { label: this.$t('fertilizerSales.godown'), class: 'text-center' },
                { label: this.$t('fertilizerSales.month'), class: 'text-center' },
                { label: this.$t('fertilizerSales.dealerName'), class: 'text-center' },
                { label: this.$t('fertilizerConfig.fertilizer_name'), class: 'text-center' },
                { label: this.$t('fertilizerSales.sales_qty'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-20' }
               ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'memo_no' },
                { key: 'sell_date' },
                { key: 'godown_bn' },
                { key: 'month_bn' },
                { key: 'dealer_bn' },
                { key: 'fertilizer_bn' },
                { key: 'quantity' },
                { key: 'action' },
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'memo_no' },
                { key: 'sell_date' },
                { key: 'godown' },
                { key: 'month_en' },
                { key: 'dealer' },
                { key: 'fertilizer' },
                { key: 'quantity' },
                { key: 'action' },
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        changeStatusCustom (baseUrl, uri, item) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
            if (response.success) {
              /** The following line commented as list is reloaded */
              // Store.dispatch('toggleStatus', item)
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        },
        toggleStatusCustom (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.deleteConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        toggleStatusCustom2 (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        remove (item) {
            this.toggleStatusCustom(seedFertilizerServiceBaseUrl, posDestroy, item)
        },
        finalSave (item) {
          this.toggleStatusCustom2(seedFertilizerServiceBaseUrl, posFinalSave, item)
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, posList, params).then(response => {
                if (response.success) {
                    // this.$store.dispatch('setList', response.data.data)
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList(data) {
          const listData = data.map(item => {
            const fertilizerObj = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(doc => doc.value === item.fertilizer_id)
            const fertilizerData = {}
            if(typeof fertilizerObj !== 'undefined') {
              fertilizerData.fertilizer = fertilizerObj.text_en
              fertilizerData.fertilizer_bn = fertilizerObj.text_bn
            } else {
              fertilizerData.fertilizer = ''
              fertilizerData.fertilizer_bn = ''
            }
            const monthObj = this.monthList.find(doc => doc.value === parseInt(item.month))
            const monthData = {}
            if(typeof monthObj !== 'undefined') {
              monthData.month_en = monthObj.text_en
              monthData.month_bn = monthObj.text_bn
            } else {
              monthData.month_en = ''
              monthData.month_bn = ''
            }
            const godownObj = this.$store.state.SeedsFertilizer.commonObj.godownInfoList.find(doc => doc.value === item.from_godown_id)
            const godownData = {}
            if(typeof godownObj !== 'undefined') {
              godownData.godown = godownObj.text_en
              godownData.godown_bn = godownObj.text_bn
              const saleCenterList = this.$store.state.commonObj.officeList.find(item => item.value === godownObj.sales_center_id)
              godownData.sale_center = saleCenterList !== undefined ? saleCenterList.text_en : ''
              godownData.sale_center_bn = saleCenterList !== undefined ? saleCenterList.text_bn : ''
            } else {
              godownData.godown = ''
              godownData.godown_bn = ''
              godownData.sale_center = ''
              godownData.sale_center_bn = ''
            }

            const dealerObj = this.dealerInfoList.find(doc => doc.value === item.dealer_id)
            const dealerData = {}
            if(typeof dealerObj !== 'undefined') {
              dealerData.dealer = dealerObj.text_en
              dealerData.dealer_bn = dealerObj.text_bn
              dealerData.trade = dealerObj.trade_name
              dealerData.trade_bn = dealerObj.trade_name_bn
              dealerData.trade_bn = dealerObj.trade_name_bn
              dealerData.licence_number = dealerObj.licence_number
              dealerData.licence_issue_date = dealerObj.licence_issue_date
              dealerData.pre_district_id = this.$store.state.commonObj.districtList.find(doc => doc.value === dealerObj.pre_district_id)
              dealerData.pre_upazilla_id = this.$store.state.commonObj.upazilaList.find(doc => doc.value === dealerObj.pre_upazilla_id)
            } else {
              dealerData.dealer = ''
              dealerData.dealer_bn = ''
              dealerData.trade = ''
              dealerData.trade_bn = ''
              dealerData.tin_no = ''
              dealerData.licence_issue_date = ''
              dealerData.pre_district_id = ''
              dealerData.pre_upazilla_id = ''
            }
            return Object.assign({}, item, fertilizerData, monthData, godownData, dealerData)
          })
          return listData
        },
        pdfExport () {
          this.$refs.details.pdfExport()
        }
    }
}
</script>

<style>
  .modal-title {
    flex-grow: 1 !important;
  }
  .w-20{width: 20%;}
</style>
